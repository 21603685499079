<template>
  <div id="dashboard">

    <div class="row no-gutters">
      <app-title title="mdl.conferma.title"></app-title>
      <div class="col-10 offset-1">
        <app-breadcrumb items="mdl.bread_home;/mdl;home "> </app-breadcrumb>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-10 offset-1 mt-5" v-if="actSlot!=null">
        <div class="m-mdl-dispo__conferma-row m-mdl-dispo__conferma-row-noborder">
          <div class="col-12">
            <div class="m-mdl-dispo__prop">{{$t('mdl.conferma.paziente')}}</div> <label ><strong>{{this.fullname}}</strong></label>
          </div>
        </div>
        <div class="m-mdl-dispo__conferma-row ">
          <div class="col-12">
            <div class="m-mdl-dispo__prop"> {{$t('mdl.conferma.prestazione')}}</div>
            <AppPrestazioni :items="this.prestazioni" mode="div"></AppPrestazioni>

          </div>
        </div>
        <div class="row m-mdl-dispo__conferma-row">
          <div class="col-12 col-md-3">
            <div class="m-mdl-dispo__prop">{{$t('mdl.conferma.data')}}</div> <label><strong>{{longData(actSlot)}}</strong></label>
          </div>
          <div class="col-12 col-md-3">
            <div class="m-mdl-dispo__prop">{{$t('mdl.conferma.ora')}}</div> <label><strong>{{actSlot.ora_inizio}}</strong></label>
          </div>
          <div class="col-12 col-md-3">
            <div class="m-mdl-dispo__prop">{{$t('mdl.conferma.durata')}}</div> <label><strong>{{actSlot.durata_media_minuti}} {{$t('mdl.conferma.minuti')}}</strong></label>
          </div>
          <div class="col-12 col-md-3">
            <div class="m-mdl-dispo__prop">{{$t('mdl.conferma.medico')}}</div> <label><strong>{{actSlot.operatore_sanitario==null?$t("mdl.dispo.no_medico"):actSlot.operatore_sanitario}}</strong></label>
          </div>
        </div>
        <div class="m-mdl-dispo__conferma-row">
          <div class="col-12">
            <div class="m-mdl-dispo__prop">{{$t('mdl.conferma.presso')}}</div> <label ><strong>{{actSlot.indirizzo_visite}}</strong></label>
          </div>
        </div>

        <div class="col-12 " v-if="errorStr!=null">
          <div class="o-registration-collapse__error" >
            <p>{{errorStr==="ERR_MESSAGE"?errorMsg:$t('mdl.conferma.errors.'+errorStr)}}</p>
          </div>
        </div>
        <div class="row mt-1 mt-lg-5">
          <div class=" col-12 d-block d-md-none ">
            <b-button variant="primary" size="md" class="text-uppercase mt-3 col-12" @click="concludi()"
            >{{ $t('mdl.conferma.concludi') }} </b-button>
          </div>

          <div class="col-12 d-block d-md-none">
            <b-button variant="outline-primary" size="md" class="text-uppercase mt-3 col-12" @click="indietro()"
            >{{ $t('mdl.conferma.indietro') }} </b-button>
          </div>

          <div class="d-none d-md-block">
            <b-button variant="outline-primary" size="md" class="text-uppercase mt-3 col-12" @click="indietro()"
            >{{ $t('mdl.conferma.indietro') }} </b-button>
          </div>

          <div class="col-8  d-none d-md-block ">
            <b-button variant="primary" size="md" class="text-uppercase mt-3 " @click="concludi()"
            >{{ $t('mdl.conferma.concludi') }} </b-button>
          </div>

        </div>

      </div>

    </div>
  </div>

</template>

<script>

import AppBreadcrumb from "@/components/molecules/AppBreadcrumb";
import AppTitle from "@/components/molecules/AppTitle";
import apiAxios from "@/libs/apiAxios";
import router from "@/router";
import AppPrestazioni from "@/components/molecules/AppPrestazioni";
export default {
  name: "dashboard",
  components: {AppPrestazioni, AppTitle, AppBreadcrumb},
  props: {},
  data() {
    return {
      info:null,
      slots:null,
      slot_guid:null,
      actSlot:null,
      visible:false,
      errorStr:null,
      errorMsg:"",
      loading:false,
      prestazioni:[],
      fullname:''

    };
  },
  computed:{
  },
  created() {

    let userdata=sessionStorage.getItem("userdata");
    userdata=JSON.parse(userdata);
    this.fullname=userdata.fullname;
    this.loadData();
    this.prestazioni=this.info.prestazione.split('\n');
    let guid=this.$route.query.slot;
    if (guid!=null)
      this.slot_guid=guid;

    this.actSlot=null;
    this.loadSlot();
    if (this.actSlot==null)
    {
      this.errorStr="ERR_NOSLOT";
    }
  },
  methods: {
    longData(slot)
    {
      const dat=slot.data;
      const dd=parseInt(dat.substring(0,2));
      const mm=dat.substring(3,5);
      const year=dat.substring(6)
      console.log(dd,mm,year);

      return this.$t('mdl.dispo.weekday.W'+slot.weekday) + " " + dd + ' ' +this.$t('mdl.dispo.month.M'+mm) + " " + year;
    },
    indietro(){
      router.push('/mdl/disponibilita');
    },
    concludi(){
      const data={
        guid:this.actSlot.guid_slot
      };
      apiAxios.post("/mdl/reserve",data).then(result=>{
        const data= result.data.ReserveResult;
        if (data.success)
        {
          sessionStorage.removeItem('mdlrequest');
          router.push("conferma-esito?success=true");
        }
        else {
          this.errorStr="ERR_MESSAGE"
          this.errorMsg=data.error;
        }
      })
    },
    loadData() {
      this.loading = true;

      let data=null;
      try
      {
        let tmp = sessionStorage.getItem('mdlrequest');
        tmp = atob(tmp);
        data = JSON.parse(tmp);
        if (data!=null)
        {
          this.info=data.query;
          this.slots=data.data;

        }
        else {
          this.errorStr="ERR_DATA";
        }
      }
      catch ( ex)
      {
        this.errorStr="ERR_DATA"
      }
      this.loading=false;
    },
    loadSlot()
    {

      const slots=this.slots.slots;
      for(var i=0;i<slots.length;i++)
      {
        for (var j=0;j<slots[i].days.length;j++)
        {
          for (var x=0;x<slots[i].days[j].slots.length;x++)
          {
            if (slots[i].days[j].slots[x].guid_slot===this.slot_guid)
            {
              this.actSlot=slots[i].days[j].slots[x];
              return;
            }
          }
        }
      }

    }

  }
};
</script>
